<template>
  <div class="container">
    <div class="page-title">
      <i class="el-icon-back" @click="back"  style="cursor:pointer"></i>
      <span style="padding-left: 8px;">
        订单列表<el-button v-if="!fromCustomerFlag" style="margin-left: 10px;" :disabled="!accessConfig.add" type="success" size="mini" icon="el-icon-folder-add" @click="create">添加订单</el-button>
      </span>
    </div>
    <div class="content">
      <div class="content-top">
<!--        <div class="first-line">-->
<!--          <div class="date-box" style="padding-left: 12px;">-->
<!--            <el-button :disabled="!accessConfig.add" type="primary" size="small" icon="el-icon-folder-add" @click="create">添加订单</el-button>-->
<!--          </div>-->
<!--        </div>-->
        <div class="first-line" v-if="!fromCustomerFlag">
          <div class="date-box">
<!--            <div class="label" style="width: 120px;">创建时间范围</div>-->
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="dateArr"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
            </el-date-picker>
          </div>
          <div class="date-box" style="padding-left: 10px;">
            <div class="label" style="width: 50px;">订单号</div>
            <el-input
              style="width: 180px;"
              placeholder="请输入内容"
              v-model="orderNo"
              clearable>
            </el-input>
          </div>
          <div class="date-box">
            <div class="label" style="width: 50px;">微信号</div>
            <el-input
              style="width: 150px;"
              placeholder="请输入内容"
              v-model="wechatForSearch"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 10px;">
            <div class="label" style="width: 50px;">旺旺名</div>
            <el-input
              style="width: 170px;"
              placeholder="请输入内容"
              v-model="accountNameForSearch"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 10px;">
            <div class="label" style="width: 40px;">客服</div>
            <el-select v-model="customerServiceValue" placeholder="请选择" style="width: 100px;">
              <el-option
                v-for="item in customerServiceListOptions"
                :key="item.id"
                :label="item.username"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 10px;">
            <div class="label" style="width: 50px;">设计师</div>
            <el-autocomplete
              style="width: 120px;"
              class="inline-input"
              v-model="designerNameForSearch"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
            ></el-autocomplete>
<!--            <el-select v-model="designerNameForSearch" filterable allow-create placeholder="请选择" style="width: 100px;">-->
<!--              <el-option-->
<!--                v-for="item in designerNameListOptions"-->
<!--                :key="item"-->
<!--                :label="item"-->
<!--                :value="item">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-input-->
<!--              style="width: 170px;"-->
<!--              placeholder="请输入内容"-->
<!--              v-model="designerNameForSearch"-->
<!--              clearable>-->
<!--            </el-input>-->
          </div>
          <div class="date-box">
            <el-button type="primary" size="small" icon="el-icon-search" @click="search">搜索</el-button>
          </div>
          <div class="date-box">
            <el-button type="primary" size="small" icon="el-icon-download" @click="downloadZip">导出</el-button>
          </div>
        </div>
        <div class="first-line" v-if="!fromCustomerFlag">
          <div class="date-box">
            <div class="label" style="width: 80px;">筛选</div>
            <div>
              <el-radio-group v-model="selectId" @change="changeHandler" size="mid">
                <el-radio-button v-for="(city,i) in selectOptions" :label="city.value" :key="city.value">{{city.label}}</el-radio-button>
              </el-radio-group>
            </div>
            <el-button size="small" type="primary" icon="el-icon-delete" @click="reset">重置</el-button>
          </div>
<!--          <div class="date-box" style="padding-left: 12px;">-->
<!--           -->
<!--          </div>-->
        </div>
      </div>
      <div class="content-bottom">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          v-loading="listLoading"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            label="创建时间"
            width="100">
            <template slot-scope="scope">
              {{scope.row.createTime | rTime}}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderNumber"
            label="订单号"
            width="110">
          </el-table-column>
          <el-table-column
            prop="accountName"
            label="旺旺名"
            width="80">
          </el-table-column>
          <el-table-column
            prop="measurement"
            label="设计要求"
            width="110">
          </el-table-column>
          <el-table-column
            prop="wechat"
            label="微信号"
            width="80">
          </el-table-column>
          <el-table-column
            prop="shopName"
            label="店铺名称"
            width="80">
          </el-table-column>
          <el-table-column
            label="金额"
            width="80">
            <template slot-scope="scope">
              {{scope.row.yuan}}
            </template>
          </el-table-column>
          <el-table-column
            label="加急"
            width="50">
            <template slot-scope="scope">
              {{scope.row.urgentFlag ? '是' : '否'}}
            </template>
          </el-table-column>
          <el-table-column
            prop="designerName"
            label="设计师"
            width="90">
          </el-table-column>
          <el-table-column
            label="订单状态"
            prop="orderStatusName"
            width="142">
            <template slot-scope="scope">
              <el-select :disabled="(scope.row.accountId && !fromCustomerFlag) ? false : true" @change="((val)=>{changeorderStatus(val, scope.row.id)})" v-model="scope.row.orderStatus" placeholder="请选择" style="width: 132px;">
                <el-option
                  v-for="item in orderStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="planStatusName"
            label="策划记录"
            width="128">
            <template slot-scope="scope">
              <el-select :disabled="(scope.row.accountId && !fromCustomerFlag) ? false : true" @change="((val)=>{changeplanStatus(val, scope.row.id)})" v-model="scope.row.planStatus" placeholder="请选择" style="width: 118px;">
                <el-option
                  v-for="item in planStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="settlementStatus"
            label="结账状态"
            width="112">
            <template slot-scope="scope">
              <el-select :disabled="!scope.row.accountId || !accessConfig.settle || fromCustomerFlag" @change="((val)=>{changesettleStatus(val, scope.row.id)})" v-model="scope.row.settlementStatus" placeholder="请选择" style="width: 102px;">
                <el-option
                  v-for="item in settleStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="createName"
            label="创建客服"
            width="80">
          </el-table-column>
          <el-table-column
            label="客户"
            width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.customerStatus === 3">黑名单</span>
              <span v-else>{{scope.row.customerStatus === 2 ? '老客户' : '新客户'}}<i v-if="!fromCustomerFlag" style="color: #F56C6C;font-size: 18px;margin-left: 5px;" class="el-icon-remove" @click="toBlacklist(scope.row.id)"></i></span>
            </template>
          </el-table-column>
          <el-table-column
            label="催回购"
            width="112">
            <template slot-scope="scope">
              <el-select :disabled="(scope.row.accountId && !fromCustomerFlag) ? false : true" @change="((val)=>{changeNextBuy(val, scope.row.id)})" v-model="scope.row.nextBuy === 1 ? '加急' : ''" placeholder="请选择" style="width: 100px;">
                <el-option
                  v-for="item in nextBuyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button :disabled="!(scope.row.accountId && accessConfig.find)" @click="handleClick2(scope.$index)" type="text" size="mid">查看</el-button>
              <el-button  :disabled="!(scope.row.accountId && accessConfig.edit && !fromCustomerFlag)"  @click="edit(scope.$index)" type="text" size="mid">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 10, 20, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog title="创建订单" :close-on-click-modal="false" :visible.sync="dialogTableVisible" center :append-to-body='true' :lock-scroll="false" width="80%">
      <div class="">
        <div class="first-line" style="margin: 0 20px 20px;width: 70%;">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="复制订单信息即可自动识别"
            clearable
            @blur="queryOldCustomer"
            v-model="textarea">
          </el-input>
        </div>
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 130px;text-align: right;">
              订单号
            </div>
            <el-input
              style="width:200px;"
              placeholder="请输入内容"
              v-model="orderNumber"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 90px;text-align: right;">旺旺名</div>
            <el-input
              @blur="queryOldCustomer"
              :disabled="disableFlag"
              style="width:200px;"
              placeholder="请输入内容"
              v-model="accountName"
              clearable>
            </el-input>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 130px;text-align: right;">尺寸、工艺、数量</div>
            <el-input
              style="width:200px;"
              placeholder="请输入内容"
              v-model="measurement"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label"  style="width: 90px;text-align: right;">微信号</div>
            <el-input
              @blur="queryOldCustomer"
              style="width:200px;"
              placeholder="请输入内容"
              v-model="wechat"
              clearable>
            </el-input>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 130px;text-align: right;">设计师名称</div>
            <el-input
              style="width:200px;"
              placeholder="请输入内容"
              v-model="designerName"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label"  style="width: 90px;text-align: right;">金额</div>
            <el-input
              style="width:200px;"
              placeholder="请输入内容"
              v-model="yuan"
              clearable>
            </el-input>
          </div>
        </div>
        <div class="first-line" style="margin-bottom: 0;">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 130px;text-align: right;">店铺名称</div>
            <el-select v-model="shopId" placeholder="请选择"  style="width:200px;">
              <el-option
                v-for="item in shopOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label"  style="width: 90px;text-align: right;">是否加急</div>
            <el-select v-model="urgentFlag" placeholder="请选择"  style="width:200px;">
              <el-option
                v-for="item in urgentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 30px;">
          <el-button width="80px" type="primary" size="mid"  @click="confirm">确定</el-button>
          <span v-if="oldCustomer && type !== 'edit'" style="color:red;padding-left: 5px;">({{customerText}})</span>
        </div>
      </div>
    </el-dialog>
    <div class="success-notice" v-if="ifSuccNotice">
      <img :src="successNotice" alt="">
      <div class="el-icon-circle-close close-notice" @click="closeSuccNotice"></div>
    </div>
  </div>
</template>
<script>
  import successNotice from "@assets/imgs/success-notice.png"
  export default {
    data() {
      return {
        pickerOptions: {
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '昨天',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                picker.$emit('pick', [start, end])
              }
            },
            {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value2: '',
        orderNo:'', //查询
        wechatForSearch:'', //查询
        accountNameForSearch:'', //查询
        designerNameForSearch:'', //查询
        orderNumber: '',
        disableFlag: false,
        selectOptions: [

        ],
        selectId: '',
        activeName: 'second',
        tableData: [],
        options: [{
          value: '选项1',
          label: '张三'
        }, {
          value: '选项2',
          label: '李四'
        }, {
          value: '选项3',
          label: '张二'
        }],
        dateArr: [],
        customerServiceListOptions:[],
        designerNameListOptions:[],
        customerServiceValue:'',
        orderStatusOptions: [
          {
            value: 8,
            label: '待处理'
          },
          {
          value: 1,
          label: '设计中'
        }, {
          value: 2,
          label: '已初稿修改中'
        }, {
          value: 3,
          label: '已定稿'
        }, {
          value: 4,
          label: '已退款'
        }, {
          value: 5,
          label: '等资料'
        }, {
          value: 6,
          label: '等修改中'
        }, {
          value: 7,
          label: '不满意'
        }, {label: '无回复',
            value: 14}
        ],
        nextBuyOptions: [
          {
            value: 1,
            label: '加急'
          }, {
            value: 2,
            label: '普通'
          }
        ],
        planStatusOptions: [ {
          value: 1,
          label: '待处理'
        }, {
          value: 2,
          label: '策划中'
        }, {
          value: 3,
          label: '已完成'
        }, {
          value: 4,
          label: '不需要策划'
        }],
        settleStatusOptions: [ {
          value: 2,
          label: '已结账'
        }, {
          value: 1,
          label: '待结账'
        }],
        value: '',
        dialogTableVisible:false,  //添加弹框
        successNotice,
        ifSuccNotice: false,
        textarea: '',
        accountName: '',  //旺旺名
        orderNumber: '',  //订单号
        // region: '',  //地区
        measurement: '',  //尺寸
        wechat: '',
        // number: '',  //数量
        // craft: '',  //工艺
        yuan: '',  //金额
        shopId: '',  //店铺id
        urgentFlag: false,  //是否加急，false true
        radioValue: '',
        activeName: 'second',
        shopOptions: [],  //店铺名称
        urgent: '', //是否加急
        //加急选项
        urgentOptions: [{
          value: true,
          label: '是'
        }, {
          value: false,
          label: '否'
        }],
        designerName: '',  //设计师姓名
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        type: '',
        id: '',
        statusLists: {
          status1: '',
          status2: '',
          status3: '',
          status4: '',
          status5: '',
          status6: '',
          status7: '',
          status8: '',
        },
        accessConfig: {},
        oldCustomer: false,
        customerText: '',
        accountIdFromCustomerList: '',
        fromCustomerFlag: false
      };
    },
    activated () {
      this.accountIdFromCustomerList = this.$route.query.accountId;
      this.fromCustomerFlag = this.accountIdFromCustomerList ? true : false;
      const functionCodeList = window.sessionStorage.functionCodeList;
      this.accessConfig = {
        add: functionCodeList.includes('service_menu_order_add'),
        find: functionCodeList.includes('service_menu_order_find'),
        edit: functionCodeList.includes('service_menu_order_edit'),
        settle: functionCodeList.includes('service_menu_order_settlement'),
      }
      this.queryOrderStatus();
      // this.queryOrderList(this.currentPage,this.pageSize);
      this.reset();
      this.$http.post("/dictionary/findChildListByParentId/4", { parentId: '4' }, { headers: {
          token: window.sessionStorage.manageToken
        }})
        .then(res => {
          console.log('res====',res)
          console.log('resdata====',res.data)
          //  console.log("输出response.data", res.data);
          // this.shopOptions = res.data;
          if (res.data.code === 'ok') {
            console.log('resdatadata====',res.data.data)
            this.shopOptions = res.data.data;
            // this.$router.push({ path: "/layout" });
          } else {
            this.$message.error(res.data.data)
          }
        });
      this.queryCustomerServiceList();
      this.queryDesignerNameList();

    },
    methods: {
      queryOrderList(currentPage,pageSize){
        console.log('this.selectId,this.dateArr,this.orderNo,this.customerServiceValue',this.selectId,this.dateArr,this.orderNo,this.customerServiceValue)
        let data = {
          orderNumber: this.orderNo || '',
          createBy: this.customerServiceValue || '',
          orderStatus: this.selectId || '',
          wechat: this.wechatForSearch || '',
          accountName: this.accountNameForSearch || '',
          designerName: this.designerNameForSearch || '',
        };
        if(this.accountIdFromCustomerList){
          data.accountId = this.accountIdFromCustomerList;
        }
        if(this.dateArr && this.dateArr.length > 0){
          data.startTime = this.dateArr[0] || '';
          data.endTime = this.dateArr[1] || '';
        }
        this.listLoading = true;
        this.$http.post(`/customerservice/findOrderByPage/${currentPage}/${pageSize}`, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data.list;
              this.total = res.data.data.total;
              console.log('this.taleData', this.tableData)
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      queryCustomerServiceList(){
        this.$http.post("/user/findCustomerService", { }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====客服了表===',res.data.data)
              this.customerServiceListOptions = res.data.data;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      queryDesignerNameList(){
        this.$http.post("/customerservice/findDesignerName", { }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====设计师列表',res.data.data)
              const designerNameListOptions = res.data.data;
              for (let item of designerNameListOptions){
                this.designerNameListOptions.push({
                  value: item
                })
              }
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      queryOrderStatus(){
        this.$http.post("/customerservice/findOrderStatus", { }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====数量===',res.data.data)
              // this.statusLists = res.data.data;
              const statusLists = res.data.data;
              this.selectOptions = [{label: '设计待处理',value: 8},
                {label: '设计中',value: 1},
                {label: '已初稿修改中',value: 2},
                {label: '已定稿',value: 3},
                {label: '已退款',value: 4},
                {label: '等资料',value: 5},
                {label: '等修改中',value: 6},
                {label: '不满意',value: 7},
                {label: '无回复',value: 14},
                {label: '加急',value: 9},
                {label: '策划中',value: 11},
                {label: '策划待处理',value: 10},
                {label: '策划完成',value: 12},
                // {label: '待回访',value: 13}
                ];
              for(let key in statusLists){
                console.log(key + '---' + statusLists[key])
                let tmpData = this.selectOptions.find((item,index) => {
                  console.log('item.value == key.charAt(key.length-1)',item.value , key.charAt(key.length-1))
                  if(item.value == key.substr(5))  item.label = item.label + (statusLists[key] || 0);
                  return item.value == key.substr(5);
                })
                console.log('tmpData',tmpData)
                // this.selectOptions.label = this.selectOptions.label + tmpData[key];
              }
              console.log('this.selectOptions',this.selectOptions)
            //   selectOptions: [
            //     {label: '设计待处理',value: 8},
            //     {label: '设计中',value: 1},
            //     {label: '已初稿修改中',value: 2},
            //     {label: '已定稿',value: 3},
            //     {label: '已退款',value: 4},
            //     {label: '等资料',value: 5},
            //     {label: '等修改中',value: 6},
            //     {label: '不满意',value: 7},
            //     {label: '加急',value: 9},
            //     {label: '策划待处理',value: 10},
            //     {label: '策划中',value: 11},
            //     {label: '策划完成',value: 12},
            //     {label: '待回访',value: 13},
            //   ],
            //   // <el-tab-pane :label="'设计待处理('+statusLists.status1+')'" name="first"></el-tab-pane>
            // <el-tab-pane :label="'策划待处理('+statusLists.status2+')'" name="second"></el-tab-pane>
            //     // <el-tab-pane :label="'设计中('+statusLists.status3+')'" name="third"></el-tab-pane>
            //     <el-tab-pane :label="'策划中('+statusLists.status4+')'" name="fourth"></el-tab-pane>
            //     // <el-tab-pane :label="'已退款('+statusLists.status5+')'" name="fifth"></el-tab-pane>
            //     // <el-tab-pane :label="'已定稿('+statusLists.status6+')'" name="sixth"></el-tab-pane>
            //     <el-tab-pane :label="'策划完成('+statusLists.status7+')'" name="seventh"></el-tab-pane>
            //     <el-tab-pane :label="'待回访('+statusLists.status8+')'" name="eighth"></el-tab-pane>
            //

              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      create(){
        this.type = 'create'
        this.accountName = ''  //旺旺名
        this.orderNumber = ''  //订单号
        this.textarea = ''
        // this.region = ''  //地区
        this.measurement = ''  //尺寸
        this.wechat = ''
        // this.number = '' //数量
        // this.craft = ''  //工艺
        this.yuan = ''  //金额
        this.shopId = ''  //店铺id
        this.urgentFlag = false  //是否加急，false true
        this.designerName = '自来稿'  //设计师姓名
        this.oldCustomer = false;
        this.disableFlag = false;
        this.dialogTableVisible = true;
        // this.$router.push({ path: "/customer-service/order-create" });
      },
      edit(index) {
        this.type = 'edit'
        console.log(index,this.tableData[index]);
        const data = this.tableData[index]
        this.textarea = ''
        this.accountName = data.accountName  //旺旺名
        this.orderNumber = data.orderNumber  //订单号
        // this.region = data.region  //地区
        this.measurement = data.measurement  //尺寸
        this.wechat = data.wechat
        // this.number = data.number //数量
        // this.craft = data.craft  //工艺
        this.yuan = data.yuan  //金额
        this.shopId = data.shopId  //店铺id
        this.urgentFlag = data.urgentFlag  //是否加急，false true
        this.designerName = data.designerName  //设计师姓名
        this.id = data.id;
        this.disableFlag = true;
        this.dialogTableVisible = true;
      },
      handleClick(){

      },
      handleClick2(index) {
        console.log(index,this.tableData[index].accountId);
        this.$router.push({ name: "CustomerDetail",query:{accountId:this.tableData[index].accountId,orderId:this.tableData[index].id} });
      },
      confirm(){
        if(!this.orderNumber){
          this.$message.error('订单号不能为空')
          return
        }
        if(!this.accountName){
          this.$message.error('旺旺名不能为空')
          return
        }
        console.log('提交');
        let data = {
          accountName: this.accountName,  //旺旺名
          orderNumber:  this.orderNumber,  //订单号
          // region:  this.region,  //地区
          measurement:  this.measurement,  //尺寸
          wechat: this.wechat,
          // number:  this.number,  //数量
          // craft:  this.craft,  //工艺
          yuan:  this.yuan,  //金额
          shopId:  this.shopId,  //店铺id
          urgentFlag:  this.urgentFlag,  //是否加急，false true
          designerName:  this.designerName  //设计师姓名
        }
        console.log('this.type',this.type)
        let url = ''
        if(this.type === 'create'){
          url = '/customerservice/saveOrder'
        }else{
          url = '/customerservice/updateOrder'
          data.id = this.id
        }
        this.$http.post(url, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.textarea = ''  //旺旺名
               this.accountName = ''  //旺旺名
               this.orderNumber = ''  //订单号
               // this.region = ''  //地区
               this.measurement = ''  //尺寸
               this.wechat = ''
               // this.number = '' //数量
               // this.craft = ''  //工艺
               this.yuan = ''  //金额
               this.shopId = ''  //店铺
               this.urgentFlag = ''  //是否加急，false true
               this.designerName = ''  //设计师姓名
               this.oldCustomer = false;
               this.dialogTableVisible = false;
               this.ifSuccNotice = true;
            } else {
              this.$message.error(res.data.data)
            }
          });

      },
      closeSuccNotice(){
        this.ifSuccNotice = false;
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      changeorderStatus(val,accountId){
        console.log('val==',val,accountId)
        this.$http.post("/plan/updateOrderStatus", {id: accountId, orderStatus: val}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message.success('状态更新成功')
              this.queryOrderList(this.currentPage,this.pageSize);
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeNextBuy(val,accountId){
        console.log('val==',val,accountId)
        this.$http.post("/plan/updateNextBuyStatus", {id: accountId, nextBuy: val}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message.success('状态更新成功')
              this.queryOrderList(this.currentPage,this.pageSize);
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeplanStatus(val,accountId){
        console.log('val==',val,accountId)
        this.$http.post("/plan/updatePlanStatus", {id: accountId, planStatus: val}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message.success('策划记录更新成功')
              this.queryOrderList(this.currentPage,this.pageSize);
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changesettleStatus(val,accountId){
        console.log('val==',val,accountId)
        this.$http.post("/customerservice/orderSettlement", {id: accountId, settlementStatus: val}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message.success('结账状态更新成功')
              this.queryOrderList(this.currentPage,this.pageSize);
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      search(){
         this.currentPage = 1;
         this.queryOrderList(this.currentPage,this.pageSize);
      },
      downloadZip(){
        let data = {
          orderNumber: this.orderNo || '',
          createBy: this.customerServiceValue || '',
          orderStatus: this.selectId || '',
          wechat: this.wechatForSearch || '',
          accountName: this.accountNameForSearch || '',
          designerName: this.designerNameForSearch || '',
        };
        if(this.dateArr && this.dateArr.length > 0){
          data.startTime = this.dateArr[0] || '';
          data.endTime = this.dateArr[1] || '';
        }
        this.listLoading = true;
        this.$http.post(`/customerservice/orderExport`, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              const data = res.data.data
              // this.tableData = res.data.data.list;
              // this.total = res.data.data.total;
              if(data){
                const url = window.baseURL + '/images'+ data;
                window.open(url)
              }
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      reset(){
        this.orderNo = '';
        this.wechatForSearch = '';
        this.accountNameForSearch = '';
        this.designerNameForSearch = '',
        this.customerServiceValue = '';
        this.selectId = '';
        this.dateArr = [];
        this.currentPage = 1;
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      changeHandler(value) {
        this.currentPage = 1;
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      tableRowClassName(row){
        if (row.row.urgentFlag) {
          return 'red-color';
        }
        return '';
      },
      queryOldCustomer(){
        if(!this.accountName && !this.wechat) return
        this.$http.post("/customerservice/checkCustomer", {accountName: this.accountName, wechat: this.wechat}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.oldCustomer = true;
              this.customerText = res.data.data;
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      toBlacklist(id){
        this.$confirm(`将该客户转为黑名单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/customerservice/addBlackCustomer", {id}, { headers: {
              token: window.sessionStorage.manageToken
            }})
            .then(res => {
              console.log('res====',res)
              console.log('resdata====',res.data)
              //  console.log("输出response.data", res.data);
              // this.shopOptions = res.data;
              if (res.data.code === 'ok') {
                console.log('resdatadata====',res.data.data)
                this.queryOrderList(this.currentPage,this.pageSize);
              } else {
                this.$message.error(res.data.data)
              }
            });
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      },
      back(){
        this.$router.go(-1);
      },
      querySearch(queryString, cb) {
        var designerNameListOptions = this.designerNameListOptions;
        var results = queryString ? designerNameListOptions.filter(this.createFilter(queryString)) : designerNameListOptions;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (designerName) => {
          return designerName.value.indexOf(queryString) >= 0
        };
      },
      handleSelect(item){
        console.log(item)
      }
    },
    watch: {
      textarea(newVal){
        console.log('值变化',newVal)
        if(newVal){
          const data = newVal.replace(/，/g,",");
          console.log('replace',data)
          const arr = data.split(",");

          arr.forEach((item,index)=>{
            const data = item && item.trim();
            switch (index) {
              case 0:
                this.orderNumber = data;  //订单号
                break;
              case 1:
                this.accountName = data;  //订单号
                break;
              case 2:
                // this.region = item;  //订单号
                this.measurement = data;  //订单号
                break;
              case 3:
                this.wechat = data;  //订单号
                break;
              case 4:
                this.designerName = data;  //订单号
                break;
              case 5:
                this.yuan = data;  //订单号
                break;
              // case 6:
              //   this.yuan = data;  //订单号
              //   break;
              // case 7:
              //   this.shopId = data;  //订单号
              //   break;
              // case 8:
              //   (data === '是'|| data === '否') && (this.urgentFlag = data === '是' ? true : false);  //订单号
              //   break;
            }
          })
        }
      }
    },
    filters: {
      rTime(data){
        if(!data) return
        var dt = new Date(data);
        var y = dt.getFullYear();
        var m = (dt.getMonth()+1).toString().padStart(2,"0");
        var d = dt.getDate().toString().padStart(2,"0");
        var h = dt.getHours().toString().padStart(2,"0");
        var mm = dt.getMinutes().toString().padStart(2,"0");
        var s = dt.getSeconds().toString().padStart(2,"0");
        return `${y}-${m}-${d} ${h}:${mm}:${s}`;
      },
      cutdownTime(date){
        if(!date) return
        const visitTime = new Date(date.substr(0, 19)).getTime();
        const nowDate = new Date().getTime();
        var time = (visitTime - nowDate)/1000;
          //剩余多少天

        if(time > 60 && time < 60 * 60) {
          time = parseInt(time / 60.0) + "分钟";
        } else if(time >= 60 * 60 && time < 60 * 60 * 24) {
          time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
            parseInt(time / 3600.0)) * 60) + "分钟";
        } else if(time >= 60 * 60 * 24) {
          time = parseInt(time / 3600.0 / 24) + "天" + parseInt((parseFloat(time / 3600.0 / 24) -
            parseInt(time / 3600.0 / 24)) * 24) + "小时" + parseInt((parseFloat(time / 3600.0) -
            parseInt(time / 3600.0)) * 60) + "分钟";
        } else {
          time = parseInt(time) + "秒";
        }
        return time

      }
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .content-bottom {
      flex:1;
      overflow: auto;
      margin-bottom: 10px;
    }
  }
  .first-line {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  .success-notice {
    width:100%;
    height:100%;
    background:rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    img {
      width: 1200px;
      position: absolute;
      top: -100px;
      left: 0;
    }
    .close-notice {
      font-size: 45px;
      color: #fff;
      opacity: 0.4;
      position: absolute;
      top: 130px;
      left: 830px;
    }
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/ .el-table .red-color {
    color: red;
  }
  /deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
</style>
